.row {
  display: flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col {
  display: flex;
  flex-direction: column;
}

.col.reverse {
  flex-direction: column-reverse;
}
