@use '@angular/material' as mat;

// enel X shared
//@import 'eis-theme/css/eis-theme.css';

@import 'primeicons/primeicons.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeng/resources/components/panel/panel.css';
@import 'primeng/resources/components/paginator/paginator.css';
@import 'primeng/resources/components/scrollpanel/scrollpanel.css';
@import 'primeng/resources/components/table/table.css';
@import "primeng/resources/themes/saga-blue/theme.css";
//@import "ptable";
// application specific
@import './styles/performance-colour-classes';
@import './styles/layout-classes';
@import "ptable";
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);
body{
  background: #EFF2F7;
  height: 100%;
  margin: 0;
  padding: 0;
}
html{
  height: 100%;
  margin: 0;
  padding: 0;
}
$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roobert', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roobert', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Roobert', 0em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Roobert', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Roobert', 0em),
  title: mat.define-typography-level(20px, 32px, 500, 'Roobert', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roobert', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roobert', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Roobert', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Roobert', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Roobert', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Roobert', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roobert', 1.5px),
);

@include mat.core($fontConfig);

.main-container {
  --text-primary-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};

  --text-accent-color: #{white};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};

  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}

$theme-colors: (
  main: var(--primary-color),
  lighter: var(--primary-lighter-color),
  darker: var(--primary-darker-color),
  200: var(--primary-color),
  contrast: (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  ),
);
$primary-palette: mat.define-palette($theme-colors, main, lighter, darker);

$mat-warn: (
  main: #c32b2q,
  lighter: #ffe6ae,
  darker: #c32b2q,
  200: #c32b2q,
  contrast: (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  ),
);

$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$primary-theme: mat.define-light-theme($primary-palette, $primary-palette, $mat-warn);
@include mat.all-component-themes($primary-theme);
.p-component{
  font-family: Roobert, sans-serif;
}
