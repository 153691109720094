@import './performance-colour-variables.scss';

.performance-gud {
  background-color: $colour-gud;
}
.performance-gud-light {
  background-color: $colour-gud-light;
}
.performance-meh {
  background-color: $colour-meh;
}
.performance-meh-light {
  background-color: $colour-meh-light;
}
.performance-bad {
  background-color: $colour-bad;
}
.performance-bad-light {
  background-color: $colour-bad-light;
}
.performance-wut {
  background-color: $colour-wut;
}
.performance-wut-light {
  background-color: $colour-wut-light;
}
